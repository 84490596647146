export const rankTitles = [
  "Obscurus",
  "Initiate",
  "Seeker",
  "Alchemist",
  "Arcanist",
  "Ritualist",
  "Emissary",
  "Archon",
  "Oracle",
  "Phantom",
  "Ascendant",
  "Eternus",
] as const;

export const romanNumerals = ["", "I", "II", "III", "IV", "V", "VI"] as const;

export const getTiersFromRank = (rankNumber?: number) => {
  if (rankNumber === undefined) return { tier: 0, subTier: 0 };
  const clampedRank = Math.max(Math.min(rankNumber, 116), 0);
  const tier = Math.floor(clampedRank / 10);
  const subTier = Math.min(clampedRank % 10, 6);
  return { tier, subTier };
};
