import { getConfig } from "@portal-frontend-ssr/config";

export const generateRankImage = ({ tier, subTier }: { tier: number; subTier?: number }) => {
  const baseAssetsURL = getConfig().BASE_ASSET_URL;

  if (!subTier) {
    return `${baseAssetsURL}/images/deadlock/ranks/${tier}/large`;
  }

  return `${baseAssetsURL}/images/deadlock/ranks/${tier}${tier === 0 ? "" : `/${subTier}`}/small`;
};
